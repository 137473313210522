<template>
  <h1>Events For Good</h1>
  <div class="events">    
    <event-card v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from '@/components/EventCard'
// import axios from 'axios'
import EventService from '@/services/EventService.js'

export default {
  name: "EventList",
  components: {
    EventCard,
  },
  data() {
    return {
      events: null
    }
  },
  created() {
    // axios.get(
    //   'https://611f86809771bf001785c9c9.mockapi.io/api/events'
    // )
    EventService.getEvents()
    .then(response => {
      console.log('events:', response.data)
      this.events = response.data
    })
    .catch(error => {
      console.log(error)
    })
  }
};
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>